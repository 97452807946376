import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormAction } from '../../../../contexts/FormActionContext';
import { Input } from '../../../shared/form-control/Input';
import XIcon from '../../../shared/icon/XIcon';
import ActionBaseProps from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';

type NumberInputActionProps = ActionBaseProps<string>;

const NumberInputAction: FC<NumberInputActionProps> = (props) => {
  const { t } = useTranslation('activity-type');
  const { response, data, required } = props;
  const { onAnswer, readOnly } = useFormAction(props);
  const [value, setValue] = useState(response || '');
  const [hasFocus, setHasFocus] = useState(false);

  const onFocus = useCallback(() => {
    setHasFocus(true);
  }, []);

  const onBlur = useCallback(() => {
    onAnswer(value);
    setValue(value);
    setHasFocus(false);
  }, [onAnswer, value]);

  const clearInput = useCallback(() => {
    setValue('');
    onAnswer('');
  }, [onAnswer]);

  useEffect(() => {
    setValue(response || '');
  }, [response]);

  const validateKeypress = useCallback((event: KeyboardEvent) => {
    const inputKey = event.key;

    // allow deletion & moving, regardless of whether or not the value is valid
    if (['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End'].includes(inputKey)) {
      return;
    }

    // only allow 0-9 & . & - & [space] characters to be pressed
    if (!/[0-9.-\s]/.test(inputKey)) {
      event.preventDefault();
      return;
    }

    const el = event.target as HTMLInputElement;
    const valueArr = el.value.split('');
    const selectionLength = (el.selectionEnd ?? 0) - (el.selectionStart ?? 0);
    valueArr.splice(el.selectionStart ?? 0, selectionLength, inputKey);
    const newValue = valueArr.join('');

    // Must have max two decimal places
    if (!/^-?([0-9 ]+(\.[0-9]{0,2})?)?$/.test(newValue)) {
      event.preventDefault();
    }
  }, []);

  return (
    <div className="flex flex-col" data-cy="number-input-action">
      <ActionTitleDescription required={required} {...data} />

      <div className="w-[300px]">
        <Input
          value={value}
          onChange={(event) => setValue(event.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={t('number-input.placeholder')}
          disabled={readOnly}
          type="text" // if this is number, it will seperate decimals with a comma on reload based on locale
          onKeyPress={validateKeypress}
        >
          <Input.Slot name="trailing">{hasFocus && <XIcon className="mr-2 h-4 w-4" onClick={clearInput} />}</Input.Slot>
        </Input>
      </div>
    </div>
  );
};

export default NumberInputAction;
